<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <div class="table-card__item-number table-card__item-number--small">
          #{{item['listing_id']}}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('etsyListing_listingId.localization_value.value')}}
            </div>
            <div class="table-card__item-info d-flex">
              <LabelCircle :color="'green'" :value="'A'" class="mr-1"/>
              {{'#' + item['listing_id'] }}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col">
            <div class="table-card__item-label">
              {{$t('etsyListing_title.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
             {{item['title']}}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--33" v-if="item['images'][0]">
            <div class="table-card__item-label">
              {{$t('etsyListing_image.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <div class="table-card__img">
                <div class="table-card__img-i">
                  <img :src="item['images'][0]['url_75x75']">
                </div>
              </div>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--33">
            <div class="table-card__item-label">
              {{$t('etsyListing_price.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item['price']"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--33">
            <div class="table-card__item-label">
              {{$t('etsyListing_qTy.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item['quantity']"
              />
            </div>
          </div>
        </div>

        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i">
            <LinkButton
                :value="$t('common_edit.localization_value.value')"
                :type="'edit'"
                :link="$store.getters.GET_PATHS.heartysanEtsyListings + `/${shopId}` + `/${item['listing_id']}`"
                v-bind:class="{'disabled-btn' : $store.getters.getEtsyListingsBtn}"
            />
          </div>
          <div class="table-card__item-btn-i">
            <LinkButton
                :value="$t('common_delete.localization_value.value')"
                :type="'delete'"
                @click.native="$emit('removeItem')"
                v-bind:class="{'disabled-btn' : $store.getters.getEtsyListingsBtn}"
            />
          </div>
          <div class="table-card__item-btn-i">
            <LinkButton
                :value="$t('common_copy.localization_value.value')"
                :type="'copy'"
                @click.native="$emit('cloneItem')"
                v-bind:class="{'disabled-btn' : $store.getters.getEtsyListingsBtn}"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>

import ValueHelper from "../../../../../../../../coreComponents/ValueHelper/ValueHelper";
import LinkButton from "../../../../../../../../UI/buttons/LinkButton/LinkButton";
// import DefaultCheckbox from "../../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import LabelCircle from "../../../../../../../../coreComponents/LabelCircle/LabelCircle";

export default {
  name: "EtsyListingsShopTableMobile",

  components: {
    ValueHelper,
    LinkButton,
    // DefaultCheckbox,
    LabelCircle,
  },

  props: {
    item: Object,
    shopId: String,
  },

  data() {
    return {
      show: false,
    }
  }

}
</script>

<style scoped>

</style>
