import { render, staticRenderFns } from "./EtsyListingsShopTableMobile.vue?vue&type=template&id=77a242ca&scoped=true"
import script from "./EtsyListingsShopTableMobile.vue?vue&type=script&lang=js"
export * from "./EtsyListingsShopTableMobile.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77a242ca",
  null
  
)

export default component.exports