<template>
  <EtsyListingsCreateUser
      :EL="EL"
      @save="(val) => save(false, val, true)"
      @saveDraft="saveDraft"
  />
</template>

<script>
  import EtsyListingsCreateUser from "./EtsyListingsCreateUser/EtsyListingsCreateUser";
  import {etsyListingsMixin} from "../../../../../mixins/etsyListingsMixins/etsyListingsMixin";
  import {EL} from "../../models/EtsyListings";
  import {heartysanDownloadFileMixin} from "@/mixins/heartysanMixins/heartysanProductsMixin";

  export default {
    name: "EtsyListingsCreate",
    components: {
      EtsyListingsCreateUser,
    },

    mixins: [etsyListingsMixin, heartysanDownloadFileMixin],

    data(){
      return{
        EL: new EL,
        dealId: -1,
      }
    },

    mounted() {
      this.getShopInfo()
      this.getEtsyListingParams()
      this.getShopIdFc()

      this.dealId = this.$route.query.dealId
      let productId = this.$route.query.productId
      this.$store.dispatch('getHeartysanProducts', productId).then((response) => {
        let data = this.getRespData(response)

        this.setFiles(data.files)

        this.EL.setTitle(data.name)
        this.EL.setDescription(data.description)

        this.$store.dispatch('getHeartysanDeal', this.dealId).then((response) => {
          let dataDeal = this.getRespData(response)
          this.EL.setPrice(dataDeal.deal.deal_price)
        }).catch(error => this.createErrorLog(error))


      }).catch(error => this.createErrorLog(error))
    },

    methods:{

      async setFiles(files) {
        if (files && files.length > 0) {
          let downloadFiles = []
          files.map((item, index) => {
            this.downloadFileFromServer(item[0]).then((dataFile) => {
              downloadFiles.push({index: index, dataFile: dataFile})
              this.checkDownloadedFiles(files, downloadFiles)
            })
          })
        }
      },

      checkDownloadedFiles(files, downloadFiles) {
        // console.log(files.length, downloadFiles.length);
        if(files.length === downloadFiles.length){
          let sorted = this._.sortBy(downloadFiles, ['index'])
          sorted.map(item => {
            this.EL.setHeartysanProductFiles(item.dataFile)
          })
          this.loadedImage = true
        }
      },


      getShopIdFc(){
        /**
         * Get shop and listing id for data
         */
        let shopId = this.$route.params.shopId
        this.EL.setShopId(shopId)
      },

      getEtsyListingParams(){
        this.$store.dispatch('getEtsyListingsParams', ).then((response) => {
          this.EL.setParams(this.getRespData(response)['params'])
        }).catch(error => this.createErrorLog(error))
      },

      getShopInfo(){
        /**
         * Get id etsy shop of the user
         * @type {{shop_id: string}}
         */

        let shopId = this.$route.params.shopId

        let data = {
          "shop_id": shopId
        }

        /**
         * We get shop information and write it down
         */

        this.$store.dispatch('getEtsyListingsShopInfo', data).then((response) => {
          this.EL.setShopInfo(this.getRespData(response))
          this.EL.setDefaultRadioValue()
        }).catch(error => this.createErrorLog(error))
      }
    },
  }
</script>

<style scoped>

</style>