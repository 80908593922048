<template>
  <div class="fragment pt-4">
    <template v-if="$store.getters.getShops.length > 0 && $store.getters.getLoadingShops !== true">
      <div class="custom-row">
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--xl-33"
             v-for="(item, index) in $store.getters.getShops"
             :key="index"
        >
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__head">
                <div class="case-cart__columns">
                  <div class="case-cart__img-block pl-0 pt-0" v-if="item['shop_param']['logo_url']">
                    <router-link :to="$store.getters.GET_PATHS.heartysanEtsyListings + '/' + item['id']">
                      <div class="case-cart__img case-cart__img--60">
                        <img :src="item['shop_param']['logo_url']" alt="img">
                      </div>
                    </router-link>
                  </div>
                  <div class="case-cart__content-block">
                    <div class="case-cart__title pr-0">
                      <router-link class="color-brown btn-style"
                                   :to="$store.getters.GET_PATHS.heartysanEtsyListings + '/' + item['id']">
                        {{ item["shop_name"] }}
                      </router-link>

                      <div class="case-cart__status">
                        <div class="etsy-letter-img"></div>
                      </div>

                      <div class="case-cart__title-sub">
<!--                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                        <div class="admin-edit" @click="editTranslate(['etsyListing_shopLink'])"></div>-->
<!--                        <a target="_blank" rel="nofollow" class="site-link" :href="item['shop_param']['shop_link']">-->
<!--                          {{ $t('etsyListing_shopLink.localization_value.value') }}-->
<!--                        </a>-->
                        https://www.etsy.com/shop/{{item["shop_name"]}}
<!--                        {{item['shop_param']['shop_link']}}-->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__body">
                <div class="case-cart__row custom-row">
                  <div class="case-cart__col custom-col custom-col--50">
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['etsyListing_shopId'])"></div>
                      {{ $t('etsyListing_shopId.localization_value.value') }}
<!--                      Shop Id-->
                    </div>
                    <div class="case-cart__content">
                      {{ item["id"] }}
                    </div>
                  </div>

                  <div class="case-cart__col custom-col custom-col--50"
                       v-if="item['shop_param']['currency_code']"
                  >
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['etsyListing_currency'])"></div>
                      {{$t('etsyListing_currency.localization_value.value')}}
                    </div>
                    <div class="case-cart__content">
                      {{item['shop_param']['currency_code']}}
                    </div>
                  </div>

                  <TableUserNameRow
                      v-if="isAdmin"
                      :item="item"
                      :colWidth="'custom-col--100'"
                  />
<!--                  <div class="case-cart__col custom-col custom-col&#45;&#45;50">-->
<!--                    <div class="case-cart__label"-->
<!--                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                      <div class="admin-edit" @click="editTranslate(['etsyListing_userId'])"></div>-->
<!--                      {{ $t('etsyListing_userId.localization_value.value') }}-->
<!--&lt;!&ndash;                      User ID&ndash;&gt;-->
<!--                    </div>-->
<!--                    <div class="case-cart__content">-->
<!--                      {{ item["user_id"] }}-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="case-cart__col custom-col" v-if="item['shop_param']['shop_link']">-->
<!--                    <div class="case-cart__label"-->
<!--                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                      <div class="admin-edit" @click="editTranslate(['etsyListing_shopLink'])"></div>-->
<!--                      {{ $t('etsyListing_shopLink.localization_value.value') }}-->
<!--                    </div>-->
<!--                    <div class="case-cart__content">-->
<!--                      <a target="_blank" rel="nofollow" class="site-link" :href="item['shop_param']['shop_link']">-->
<!--                        {{ item["shop_name"] }}-->
<!--                      </a>-->
<!--                    </div>-->
<!--                  </div>-->
                </div>
              </div>

              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto"
                     v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['etsyTransfer_ViewListings'])"></div>
                  <router-link class="color-brown" :to="$store.getters.GET_PATHS.heartysanEtsyListings + '/' + item['id']">
                    <CaseCartButton
                         :value="$t('etsyTransfer_ViewListings.localization_value.value')"
                        :ico="true"
                        :typeIco="'show'"
                    >
                    </CaseCartButton>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
<!--          <ShowMore-->
<!--              v-if="$store.getters.getShopsCommonList.next_page_url !== null && $store.getters.getShops.length > 0"-->
<!--              @click.native="$emit('showMore')"-->
<!--              :count="$store.getters.getShopsCommonList.total - $store.getters.getShopsForPage < $store.getters.getShopsForPage ?-->
<!--                  $store.getters.getShopsCommonList.total - $store.getters.getShopsForPage:-->
<!--                  $store.getters.getShopsForPage"-->
<!--          />-->
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getLoadingShops === false && $store.getters.getShops.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>
  </div>
</template>

<script>


import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
// import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
import TableUserNameRow from "../../../../../../coreComponents/TableComponents/TableUserNameRow/TableUserNameRow";
import CaseCartButton from "../../../../../../UI/buttons/CaseCartButton/CaseCartButton";

export default {
  name: "EtsyListingsTable",
  components: {
    NoResult,
    // ShowMore,
    TableUserNameRow,
    CaseCartButton,
  },

  props: {
    countFilterParams: Number,
  },

  data() {
    return {}
  },

  methods: {}
}
</script>

<style scoped>

.etsy-letter-img{
  display: block;
  width: 20px;
  height: 20px;
  background: url("../../../../../../../assets/img/common/etsy-letter.png") center center no-repeat;
}


.unidentified-payments-table {
  padding-top: 24px;
}
</style>