<template>
  <div class="fragment">
    <StepContent
      :EL="EL"
      :editingSign="true"
    />

    <div class="cus-section cus-section--btn">
      <div class="cus-section__footer order-create__footer">
        <div class="order-create__footer-link"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_cancel'])"></div>
          <router-link :to="$store.getters.GET_PATHS.heartysanEtsyListings"
                       class="site-link site-link--alt"
          >
            {{ $t('common_cancel.localization_value.value') }}
          </router-link>
        </div>


        <div class="order-create__footer-btn ml-auto"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_save'])"></div>
<!--          <MainButton-->
<!--              class="order-create__footer-btn-i secondary"-->
<!--              :value="$t('common_SaveInDrafts.localization_value.value')"-->
<!--              :tooltip="true"-->
<!--          >-->
<!--            <template slot="tooltip">-->
<!--              <p>-->
<!--                <b>{{ $t('common_drafts.localization_value.value') }}</b>-->
<!--              </p>-->
<!--              <p>-->
<!--                {{ $t('common_savingInDrafts.localization_value.value') }}-->
<!--              </p>-->
<!--            </template>-->
<!--          </MainButton>-->

          <MainButton
              class="order-create__footer-btn-i"
              :value="$t('common_save.localization_value.value')"
              @click.native="$emit('save')"
              v-bind:class="{'disabled-btn' : $store.getters.getEtsyListingsBtn}"
          />
        </div>
      </div>
    </div>
<!--    <div class="cus-section">-->
<!--      <div class="cus-section__inner">-->
<!--        <div class="cus-section__info mw-370">-->
<!--          <div class="section-label large">-->
<!--            Photos-->
<!--          </div>-->
<!--          <p>-->
<!--            Add as many as you can so buyers can see every detail.-->
<!--          </p>-->

<!--          <div class="section-label">-->
<!--            Photos *-->
<!--          </div>-->
<!--          <p>-->
<!--            Use up to ten photos to show your item's most important qualities. <br>-->
<!--            Tips:<br>-->
<!--            &ndash; Use natural light and no flash.<br>-->
<!--            &ndash; Include a common object for scale.<br>-->
<!--            &ndash; Show the item being held, worn, or used.<br>-->
<!--            &ndash; Shoot against a clean, simple background.-->
<!--          </p>-->

<!--          <div class="section-label">-->
<!--            Link photos to variations-->
<!--          </div>-->

<!--          <p>-->
<!--            Add photos to your variations so buyers can see all their options. Try it out-->
<!--          </p>-->
<!--        </div>-->
<!--        <div class="cus-section__list">-->
<!--          <PhotoListings/>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="cus-section">-->
<!--      <div class="custom-row mb-0">-->
<!--        <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;md-100">-->
<!--          <div class="section-label large">-->
<!--            Status-->

<!--            <v-popover-->
<!--                class="site-tooltip site-tooltip&#45;&#45;radio-after"-->
<!--                :disabled="!tooltipAddProductActive"-->
<!--                offset="5"-->
<!--                placement="top-left"-->
<!--                trigger="hover"-->

<!--            >-->

<!--              <TooltipBtn class="top-left"/>-->

<!--              <template slot="popover">-->
<!--                <p>-->
<!--                  <b>What is Status</b>-->
<!--                </p>-->
<!--                <p>-->
<!--                  Please, choose the status of the product:-->
<!--                  <b>Active</b> - if you want to start sale it;-->
<!--                  <b>Unactive</b> - if you want just save the information about the product, but not sale;-->
<!--                  <b>Draft</b> - if you don’t have the full information for the product and can’t start selling it-->
<!--                </p>-->
<!--              </template>-->
<!--            </v-popover>-->
<!--          </div>-->
<!--          <div class="custom-row">-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'Active'"-->
<!--                  :name="'status'"-->
<!--                  :value="true"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'Unactive'"-->
<!--                  :name="'status'"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'Save As Draft'"-->
<!--                  :name="'status'"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="cus-section">-->
<!--      <div class="custom-row">-->
<!--        <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;sxl-66 custom-col&#45;&#45;md-100">-->
<!--          <div class="section-label large">-->
<!--            Listing Details-->
<!--          </div>-->

<!--          <DefaultInput-->
<!--              :type="'text'"-->
<!--              :label="'Title'"-->
<!--              v-model="input01"-->
<!--          />-->
<!--        </div>-->

<!--        <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;sxl-66 custom-col&#45;&#45;md-100">-->
<!--          <div class="section-label large">-->
<!--            Type-->

<!--            <v-popover-->
<!--                class="site-tooltip site-tooltip&#45;&#45;radio-after"-->
<!--                :disabled="!tooltipAddProductActive"-->
<!--                offset="5"-->
<!--                placement="top-left"-->
<!--                trigger="hover"-->

<!--            >-->

<!--              <TooltipBtn class="top-left"/>-->

<!--              <template slot="popover">-->
<!--                <p>-->
<!--                  <b>What is Type</b>-->
<!--                </p>-->
<!--                <p>-->
<!--                  <b>Physical</b> A tangible item that you will ship to buyers. <br>-->
<!--                  <b>Digital</b> A digital file that buyers will download.-->

<!--                </p>-->
<!--              </template>-->
<!--            </v-popover>-->
<!--          </div>-->


<!--          <div class="custom-row mb-0">-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'Phisical'"-->
<!--                  :name="'type'"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'Digital'"-->
<!--                  :name="'type'"-->
<!--              />-->
<!--            </div>-->

<!--          </div>-->
<!--        </div>-->

<!--        <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;sxl-66 custom-col&#45;&#45;md-100">-->
<!--          <div class="section-label large">-->
<!--            Who Made It-->
<!--          </div>-->

<!--          <div class="custom-row mb-0">-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'I did'"-->
<!--                  :name="'type1'"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'Another Shop'"-->
<!--                  :name="'type1'"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'Other company'"-->
<!--                  :name="'type1'"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->

<!--        </div>-->

<!--        <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;xl-50 custom-col&#45;&#45;md-66 custom-col&#45;&#45;xs-100">-->
<!--          <div class="section-label large">-->
<!--            What is It-->
<!--          </div>-->

<!--          <div class="custom-row mb-0">-->
<!--            <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'Finished product'"-->
<!--                  :name="'type2'"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'Tool or Supply '"-->
<!--                  :name="'type2'"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="custom-col custom-col&#45;&#45;16 custom-col&#45;&#45;xl-33 custom-col&#45;&#45;sxl-33 custom-col&#45;&#45;md-100">-->
<!--          <div class="section-label large">-->
<!--            When it was made-->
<!--          </div>-->

<!--          <DefaultSelect-->
<!--              :options="options"-->
<!--              :label="'Creation'"-->
<!--              :selected="'Recently - 2020'"-->
<!--          />-->
<!--        </div>-->

<!--        <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;md-100">-->
<!--          <div class="section-label large">-->
<!--            Category-->
<!--          </div>-->

<!--          <DefaultInput-->
<!--              :type="'text'"-->
<!--              :label="'Start typing to choose category'"-->
<!--              v-model="input02"-->
<!--              :readonly="true"-->
<!--              @click.native="changeCategoryPopup(true)"-->
<!--          />-->
<!--        </div>-->

<!--        <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;md-100">-->
<!--          <div class="section-label large">-->
<!--            Renewal Options-->

<!--            <v-popover-->
<!--                class="site-tooltip site-tooltip&#45;&#45;radio-after"-->
<!--                :disabled="!tooltipAddProductActive"-->
<!--                offset="5"-->
<!--                placement="top-left"-->
<!--                trigger="hover"-->

<!--            >-->

<!--              <TooltipBtn class="top-left"/>-->

<!--              <template slot="popover">-->
<!--                <p>-->
<!--                  <b>Renewal Options</b>-->
<!--                </p>-->
<!--                <p>-->
<!--                  Each renewal lasts for four months or until the listing sales out.-->
<!--                  <b>Authomatic</b>will renew as it expires for $0,20 USD each time (recommended option),-->
<!--                  <b>manual</b> is listing which is needed to renew by yourself, but it’s free-->
<!--                </p>-->
<!--              </template>-->
<!--            </v-popover>-->
<!--          </div>-->


<!--          <div class="custom-row mb-0">-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'Authomatic'"-->
<!--                  :name="'type3'"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'Manual'"-->
<!--                  :name="'type3'"-->
<!--              />-->
<!--            </div>-->

<!--          </div>-->
<!--        </div>-->

<!--      </div>-->
<!--    </div>-->

<!--    <div class="cus-section">-->
<!--      <div class="custom-row">-->
<!--        <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;md-100">-->
<!--          <div class="section-label large">-->
<!--            Description-->
<!--          </div>-->
<!--          Start with a brief overview that describes your item’s finest features. Shoppers will only see the first few lines of your description at first, so make it count!<br>-->
<!--          <br>-->
<!--          Not sure what else to say? Shoppers also like hearing about your process, and the story behind this item.-->

<!--        </div>-->

<!--        <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;md-100">-->
<!--          <TextEditor-->
<!--              :value="text"-->
<!--              @input="inputTextEditor"-->
<!--          />-->
<!--        </div>-->

<!--        <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;sxl-66 custom-col&#45;&#45;md-100">-->
<!--          <div class="section-label large">-->
<!--            Shipping template (ceramics)-->

<!--            <v-popover-->
<!--                class="site-tooltip site-tooltip&#45;&#45;radio-after"-->
<!--                :disabled="!tooltipAddProductActive"-->
<!--                offset="5"-->
<!--                placement="top-left"-->
<!--                trigger="hover"-->

<!--            >-->

<!--              <TooltipBtn class="top-left"/>-->

<!--              <template slot="popover">-->
<!--                <p>-->
<!--                  <b>Shipping template (ceramics)</b>-->
<!--                </p>-->
<!--                <p>-->
<!--                  Shipping template (ceramics)-->
<!--                </p>-->
<!--              </template>-->
<!--            </v-popover>-->
<!--          </div>-->

<!--          <div class="custom-row mb-0">-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'1-2kg'"-->
<!--                  :name="'type4'"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'3—4kg'"-->
<!--                  :name="'type4'"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'less than 1kg'"-->
<!--                  :name="'type4'"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->

<!--        </div>-->

<!--        <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;sxl-66 custom-col&#45;&#45;md-100">-->
<!--          <div class="section-label large">-->
<!--            Select Shop-->
<!--          </div>-->


<!--          <div class="custom-row mb-0">-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'Porcelain USSR'"-->
<!--                  :name="'type6'"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'VIKING ARTIFACTS'"-->
<!--                  :name="'type6'"-->
<!--              />-->
<!--            </div>-->

<!--          </div>-->
<!--        </div>-->

<!--        <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;sxl-66 custom-col&#45;&#45;md-100">-->
<!--          <div class="section-label large">-->
<!--            Tags — up to 12 (optional)-->
<!--          </div>-->

<!--          <DefaultInput-->
<!--              :type="'text'"-->
<!--              :label="'Type tag and press enter to add'"-->
<!--              v-model="input03"-->
<!--          />-->
<!--          <span class="soft-text font-weight-norma mt-3 ml-auto wfc d-flex site-link">Press enter to add</span>-->
<!--        </div>-->

<!--        <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;sxl-66 custom-col&#45;&#45;md-100">-->
<!--          <div class="section-label large">-->
<!--            Material — up to 13 (optional)-->
<!--          </div>-->

<!--          <DefaultInput-->
<!--              :type="'text'"-->
<!--              :label="'Type material and press enter to add'"-->
<!--              v-model="input04"-->
<!--          />-->
<!--          <span class="soft-text font-weight-norma mt-3 ml-auto wfc d-flex site-link">Press enter to add</span>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="cus-section">-->
<!--      <div class="custom-row">-->
<!--        <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;sxl-66 custom-col&#45;&#45;md-100">-->
<!--          <div class="section-label large">-->
<!--            Weight Unit-->
<!--          </div>-->

<!--          <div class="custom-row mb-0">-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'OZ'"-->
<!--                  :name="'type6'"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'KG'"-->
<!--                  :name="'type6'"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'other'"-->
<!--                  :name="'type6'"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->

<!--        </div>-->

<!--        <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;sxl-66 custom-col&#45;&#45;md-100">-->
<!--          <div class="section-label large">-->
<!--            Dimensions Unit-->
<!--          </div>-->

<!--          <div class="custom-row mb-0">-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'In'"-->
<!--                  :name="'type7'"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'CM'"-->
<!--                  :name="'type7'"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0">-->
<!--              <LargeRadio-->
<!--                  class="soft-text"-->
<!--                  :label="'M'"-->
<!--                  :name="'type7'"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->

<!--        </div>-->

<!--        <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;sxl-66 custom-col&#45;&#45;md-100 mb-0">-->
<!--          <div class="section-label large">-->
<!--            Dimensions-->
<!--          </div>-->

<!--          <div class="custom-row mb-0">-->
<!--            <div class="custom-col custom-col&#45;&#45;25 custom-col&#45;&#45;sm-100 custom-col&#45;&#45;md-50">-->
<!--              <DefaultInput-->
<!--                  :type="'text'"-->
<!--                  :label="'Weight'"-->
<!--                  v-model="w"-->
<!--                  :placeholder="'e.g. 10'"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;25 custom-col&#45;&#45;sm-100 custom-col&#45;&#45;md-50">-->
<!--              <DefaultInput-->
<!--                  :type="'text'"-->
<!--                  :label="'Height, cm'"-->
<!--                  v-model="h"-->
<!--                  :placeholder="'e.g. 10'"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;25 custom-col&#45;&#45;sm-100 custom-col&#45;&#45;md-50">-->
<!--              <DefaultInput-->
<!--                  :type="'text'"-->
<!--                  :label="'Width, cm'"-->
<!--                  v-model="wi"-->
<!--                  :placeholder="'e.g. 10'"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;25 custom-col&#45;&#45;sm-100 custom-col&#45;&#45;md-50">-->
<!--              <DefaultInput-->
<!--                  :type="'text'"-->
<!--                  :label="'Length, cm'"-->
<!--                  v-model="l"-->
<!--                  :placeholder="'e.g. 10'"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->

<!--        </div>-->

<!--        <div class="custom-col custom-col&#45;&#45;12-5 custom-col&#45;&#45;sxl-33 custom-col&#45;&#45;md-50">-->
<!--          <div class="section-label large">-->
<!--            Price-->

<!--            <v-popover-->
<!--                class="site-tooltip site-tooltip&#45;&#45;radio-after"-->
<!--                :disabled="!tooltipAddProductActive"-->
<!--                offset="5"-->
<!--                placement="top"-->
<!--                trigger="hover"-->

<!--            >-->

<!--              <TooltipBtn/>-->

<!--              <template slot="popover">-->
<!--                <p>-->
<!--                  <b>Price  </b>-->
<!--                </p>-->
<!--                <p>-->
<!--                  Remember to factor in the costs of materials, labor, and other business expenses. If you offer free shipping, make sure to include the cost of shipping so it doesn't eat into your profits.-->
<!--                </p>-->
<!--              </template>-->
<!--            </v-popover>-->
<!--          </div>-->


<!--          <InputSum-->
<!--              class="mw-138"-->
<!--              :icoType="'dollar'"-->
<!--              v-model="sum"-->
<!--              :placeholder="'0'"-->
<!--          />-->
<!--        </div>-->

<!--        <div class="custom-col custom-col&#45;&#45;12-5 custom-col&#45;&#45;sxl-33 custom-col&#45;&#45;md-50">-->
<!--          <div class="section-label large">-->
<!--            Q-ty-->

<!--            <v-popover-->
<!--                class="site-tooltip site-tooltip&#45;&#45;radio-after"-->
<!--                :disabled="!tooltipAddProductActive"-->
<!--                offset="5"-->
<!--                placement="top"-->
<!--                trigger="hover"-->

<!--            >-->

<!--              <TooltipBtn/>-->

<!--              <template slot="popover">-->
<!--                <p>-->
<!--                  <b>Quantity</b>-->
<!--                </p>-->
<!--                <p>-->
<!--                  For quantities greater than one, this listing will renew automatically until it sells out. You’ll be charged a $0.20 USD listing fee each time.-->
<!--                </p>-->
<!--              </template>-->
<!--            </v-popover>-->
<!--          </div>-->

<!--          <InputQuantity-->
<!--              v-model="q"-->
<!--              @change="changeValueQuantity"-->
<!--          />-->

<!--        </div>-->

<!--        <div class="custom-col custom-col&#45;&#45;25 custom-col&#45;&#45;sxl-33 custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-100">-->
<!--          <div class="section-label large">-->
<!--            SKU-->

<!--            <v-popover-->
<!--                class="site-tooltip site-tooltip&#45;&#45;radio-after"-->
<!--                :disabled="!tooltipAddProductActive"-->
<!--                offset="5"-->
<!--                placement="top"-->
<!--                trigger="hover"-->

<!--            >-->

<!--              <TooltipBtn/>-->

<!--              <template slot="popover">-->
<!--                <p>-->
<!--                  <b>SKU (optional)</b>-->
<!--                </p>-->
<!--                <p>-->
<!--                  SKUs are for your use only—buyers won’t see them. <br>-->
<!--                  <a href="#">Learn more about SKU.</a>-->
<!--                </p>-->
<!--              </template>-->
<!--            </v-popover>-->
<!--          </div>-->

<!--          <DefaultInput-->
<!--              :type="'text'"-->
<!--              :label="'SKU'"-->
<!--              v-model="input05"-->
<!--          />-->

<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="cus-section cus-section&#45;&#45;btn">-->
<!--      <div class="cus-section__footer order-create__footer">-->
<!--        <div class="order-create__footer-link">-->
<!--          <router-link :to="$store.getters.GET_PATHS.heartysanEtsyListings"-->
<!--                       class="site-link site-link&#45;&#45;alt"-->
<!--          >-->
<!--            Cancel-->
<!--          </router-link>-->
<!--        </div>-->


<!--        <div class="order-create__footer-btn ml-auto">-->
<!--          <MainButton-->
<!--              class="order-create__footer-btn-i secondary"-->
<!--              :value="$t('common_SaveInDrafts.localization_value.value')"-->
<!--              :tooltip="true"-->
<!--          >-->
<!--            <template slot="tooltip">-->
<!--              <p>-->
<!--                <b>{{$t('common_drafts.localization_value.value')}}</b>-->
<!--              </p>-->
<!--              <p>-->
<!--                {{$t('common_savingInDrafts.localization_value.value')}}-->
<!--              </p>-->
<!--            </template>-->
<!--          </MainButton>-->

<!--          <MainButton-->
<!--              class="order-create__footer-btn-i"-->
<!--              :value="'Create'"-->
<!--          />-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--    <CategoryPopup-->
<!--      v-if="isModalCategoryPopup"-->
<!--      @close="changeCategoryPopup(false)"-->
<!--    />-->



  </div>
</template>

<script>
  // import LargeRadio from "../../../../../UI/radiobuttons/LargeRadio/LargeRadio";
  // import TooltipBtn from "../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
  // import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
  // import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  // import PhotoListings from "./PhotoListings/PhotoListings";
  // import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  // import TextEditor from "../../../../../coreComponents/TextEditor/TextEditor";
  // import InputSum from "../../../../../UI/inputs/InputSum/InputSum";
  // import InputQuantity from "../../../../../UI/inputs/InputQuantity/InputQuantity";
  // import CategoryPopup from "../../../popups/CategoryPopup/CategoryPopup";

  import StepContent from "../../components/StepContent/StepContent";
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";

  export default {
    name: "EtsyListingsEditUser",
    components: {
      MainButton,
      // LargeRadio,
      // TooltipBtn,
      // DefaultInput,
      // MainButton,
      // PhotoListings,
      // DefaultSelect,
      // TextEditor,
      // InputSum,
      // InputQuantity,
      // CategoryPopup,
      StepContent,
    },

    props:{
      EL: Object,
    },

    data() {
      return {
        tooltipAddProductActive: true,
        text: '',
        isModalCategoryPopup: false,
      }
    },

    methods:{
      inputTextEditor(val){
        this.text = val
      },

      changeCategoryPopup(val){
        this.isModalCategoryPopup = val
      }
    }
  }
</script>

<style scoped>
  .mw-138{
    max-width: 138px;
  }
</style>
