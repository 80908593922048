
<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['etsyListing_shopId',])"></div>
        <DefaultInput
            :label="$t('etsyListing_shopId.localization_value.value')"
            :type="'text'"
            v-model="shopId"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['etsyListing_shopName',])"></div>
        <DefaultInput
            :label="$t('etsyListing_shopName.localization_value.value')"
            :type="'text'"
            v-model="shopName"
        />
      </div>

      <div class="table-filter__item"
           v-if="$store.getters.getIsAdminRights === 'admin'"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_user',])"></div>
        <SearchSelect
            :options="options"
            :label="$t('common_user.localization_value.value')"
            :optionsLabel="'email.contacts[0]'"
            :functionSearch="functionSearch"
            @change="changeUsersFilter"
            :selected="userName"
            :typeSelect="'users'"
            :userEmail="true"
        />
      </div>
    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import SearchSelect from "../../../../../../UI/selectiones/SearchSelect/SearchSelect";
  import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";

  export default {
    name: "EtsyListingsFilter",
    components: {
      FilterBlock,
      MainButton,
      DefaultInput,
      SearchSelect,
    },

    props: [
      'showFilter',
      'filterGetParams',
    ],

    mixins: [filterFunc, usersFunc],

    data(){
      return {
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',
        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        shopId: this.filterGetParams.shopId ? this.filterGetParams.shopId : '',
        shopName: this.filterGetParams.shopName ? this.filterGetParams.shopName : '',


        options: [],

        filterCounts: [
          'userName',
          'userId',
          'shopId',
          'shopName'
        ]
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.userName = newVal.userName ? newVal.userName : ''
        this.userId = newVal.userId ? newVal.userId : ''
        this.shopId = newVal.shopId ? newVal.shopId : ''
        this.shopName = newVal.shopName ? newVal.shopName : ''
      },

    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)
        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)
        this.$emit('changeFilter', data)
      },

    },
  }
</script>

<style scoped>

</style>
